/* You can add global styles to this file, and also import other style files */
@import url('http://fonts.cdnfonts.com/css/avenir-next-lt-pro');
@import url('http://fonts.cdnfonts.com/css/avenir');

/* @media (max-width: 575.98px) { 
    
     .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 98% !important;
    } 
    .cntdiv {
        border-right: solid 2px #efe4c0;
        overflow-y: scroll;
        height: 502px;
        padding: 10px;
    }    
}
@media (min-width: 990px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 98% !important;
    }
  
    .qrdiv{
        bottom: 0;
        position: fixed;
        margin-bottom: 4%;
    }
    .cntdiv{
        border-right: solid 2px #efe4c0;
        overflow-y: scroll;
        height:calc(100vh - 120px);
        padding: 10px;
    }
} */


  
.hdrcls{
    color: rgb(106,20,52);
}


.navbar-light .navbar-nav .nav-link {
    color: rgb(191,144,0) !important;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Avenir', sans-serif;
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.ftrmnu{
    float: right;
}
.ftrmnu .navbar-nav .nav-link {
    font-size: 18px !important;
    font-weight: 400 !important;
    font-family: 'Avenir', sans-serif;
    color: #821434 !important;
}
@media (min-width: 992px){
    .ftrmnu .navbar-nav .nav-link {
        padding-right: 70px !important;
        padding-left: 70px !important;
    }
}



a:hover {
    color: rgb(191,144,0) !important;
}

.star-rating {
    margin: 25px 0 0px;
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    width: 175px;
    height: 35px;
    overflow: hidden;
    position: relative;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjREREREREIiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
}

.star-rating i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 1;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZERjg4IiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
}

.star-rating input {
   
    opacity: 0;
    display: inline-block;
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
}

.star-rating input:hover+i,
.star-rating input:checked+i {
    opacity: 1;
}

.star-rating i~i {
    width: 40%;
}

.star-rating i~i~i {
    width: 60%;
}

.star-rating i~i~i~i {
    width: 80%;
}

.star-rating i~i~i~i~i {
    width: 100%;
}

.choice {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
    display: block;
}

span.scale-rating {
    margin: 5px 0 15px;
    display: inline-block;
    width: 100%;
}

span.scale-rating>label {
    position: relative;
    outline: 0 !important;
    border: 1px solid grey;
    height: 33px;
    margin: 0 5px 0 0;
    width: calc(10% - 7px);
    float: left;
    cursor: pointer;
}

span.scale-rating label {
    position: relative;
    outline: 0 !important;
    height: 33px;
    margin: 0 5px 0 0;
    width: calc(10% - 7px);
    float: left;
    cursor: pointer;
}

span.scale-rating input[type=radio] {
    position: absolute;
   
    opacity: 0;
    outline: 0 !important;
    height: 33px;
    margin: 0 5px 0 0;
    width: 100%;
    float: left;
    cursor: pointer;
    z-index: 3;
}

span.scale-rating label:hover {
    background: #fddf8d;
}

span.scale-rating input[type=radio]:last-child {
    border-right: 0;
}

span.scale-rating label:before {
    content: attr(value);
    top: 7px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    vertical-align: middle;
    z-index: 2;
}
.feedback input, .feedback optgroup, .feedback select, .feedback textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    
    border: solid 1px #E02473 !important;
}
.col-form-label, .txtclr {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
    color: #E02473;
}

.business-feedback {
    top: 20%;
    left: 15%;
    width:80em;
    margin-top: 3px; 
    position:relative;
}


