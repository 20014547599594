

.class-one{
    margin:0cm;
    font-size:15px;
    font-family:"Calibri",sans-serif;
}
.class-two{
    margin-top:0cm;
    margin-right:0cm;
    margin-bottom:0cm;
    margin-left:36.0pt;
    font-size:15px;
    font-family:"Calibri",sans-serif;
}

.class-three{
    margin-bottom:0cm;
    list-style-type: disc;
}
.class-four{
    margin:0cm;
    font-size:15px;
    font-family:"Calibri",sans-serif;
    margin-left:36.0pt;
}
.class-five{
    list-style-type: disc;
}

.font-size-13{
    font-size:13px;
}
.font-size-14{
    font-size:14px;
}
.font-size-16{
    font-size:16px;
}