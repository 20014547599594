/* You can add global styles to this file, and also import other style files */
@import url('http://fonts.cdnfonts.com/css/avenir-next-lt-pro');
@import url('http://fonts.cdnfonts.com/css/avenir');

@media (max-width: 575.98px) { 
      /* .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 98% !important;
    }  */
    .cntdiv {
        border-right: solid 2px #efe4c0;
        overflow-y: scroll;
        height: 502px;
        padding: 10px;
    }    
}
@media (min-width: 990px){
    /* .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 98% !important;
    } */
    
    .qrdiv{
        bottom: 0;
        position: fixed;
        margin-bottom: 4%;
    }
    .cntdiv{
        border-right: solid 2px #efe4c0;
        overflow-y: scroll;
        height:calc(100vh - 120px);
        padding: 10px;
    }
}


/* * {
	margin:0px;
	padding:0px;
	border:none;
	outline:none;
} */

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

 
  
.hdrcls{
    color: rgb(106,20,52);
}



.t{
    border-radius: 50%;
    border: solid 1px rgb(106,20,52);
    padding: 10px;
    margin-top: 8px;
   
}
/* .container{
    background: white;
    min-height: 500px;
    max-height: auto;
} */

/*.hr-border{
    height:4px !important;
    border-width:0;
    color:rgb(191,144,0);
    background-color:rgb(191,144,0)
}*/

.hr-border {
    border-top: 3px solid rgb(191,144,0) !important;
    color: rgb(191,144,0) !important;
    overflow: visible !important;
    text-align: center !important;
    height: 2px !important;
    background-color: rgb(191,144,0) !important;
    width: 75%;
    float:left;
}
.hr-border2 {
    border-top: 3px solid rgb(191,144,0) !important;
    color: rgb(191,144,0) !important;
    overflow: visible !important;
    text-align: center !important;
    height: 2px !important;
    background-color: rgb(191,144,0) !important;
    width: 20%;
    float:right;
}

.hr-border::after {
    background: #fff;   
    padding: 0 4px !important;
    position: relative !important;
    top: -13px !important;
}
.btn-primary{
    margin: auto 0px;
    background-color: #E02473 !important;
    border-color: #E02473 !important;
    border-radius: 40px !important;
}

.es-padding {
    padding-left: 5px;
    padding-right: 5px;
}
.es-box {
    background-color: #FFFFFF;
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #eef1f7;
    overflow: hidden;
    position: relative;
}
.es-box {
    margin-bottom: 10px;
}
.es-user {
    align-items: center;
    display: flex;
    position: relative;
}
.es-user {
    align-items: center;
    display: flex;
    position: relative;
}
.es-user-details {
    display: inline-block;
    margin-right: 10px;
    overflow: hidden;
    width: 100%;
}
.es-username {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.es-date {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.es-media {
    position: relative;
}
.es-media svg {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
}
.es-photo, #es-feed-2 .es-video {
    border-radius: 10px;
    overflow: hidden;
   /* padding-top: 100%;*/
    position: relative;
}
.mb-0 {
    margin-bottom: 0;
}
.es-text {
    line-height: 1.5;
    word-break: break-word;
    word-wrap: break-word;
    margin-bottom: 20px;
    color: #000000;
    white-space: pre-line;
}
.es-text {
    line-height: 1.5;
    word-break: break-word;
    word-wrap: break-word;
    margin-bottom: 20px;
    color: #000000;
    white-space: pre-line;
}
.slick-slider {  
    width: 90%;  
    margin: auto;  
}


/* .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
  } */
  .es-box {
    background-color: #FFFFFF;
    padding: 15px;
    margin-bottom: 15px;
    width: 100% !important;
    border-radius: 8px;
    border: 2px solid #eef1f7;
    overflow: hidden;
    position: relative;
   }
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100% !important;
}
.star-rating {
    margin: 25px 0 0px;
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    width: 175px;
    height: 35px;
    overflow: hidden;
    position: relative;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjREREREREIiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
}

.star-rating i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 1;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZERjg4IiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
}

.star-rating input {
    -moz-appearance: none;
    -webkit-appearance: none;
    opacity: 0;
    display: inline-block;
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
}

.star-rating input:hover+i,
.star-rating input:checked+i {
    opacity: 1;
}

.star-rating i~i {
    width: 40%;
}

.star-rating i~i~i {
    width: 60%;
}

.star-rating i~i~i~i {
    width: 80%;
}

.star-rating i~i~i~i~i {
    width: 100%;
}

.choice {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
    display: block;
}

span.scale-rating {
    margin: 5px 0 15px;
    display: inline-block;

    width: 100%;

}

span.scale-rating>label {
    position: relative;
    -webkit-appearance: none;
    outline: 0 !important;
    border: 1px solid grey;
    height: 33px;
    margin: 0 5px 0 0;
    width: calc(10% - 7px);
    float: left;
    cursor: pointer;
}

span.scale-rating label {
    position: relative;
    -webkit-appearance: none;
    outline: 0 !important;
    height: 33px;

    margin: 0 5px 0 0;
    width: calc(10% - 7px);
    float: left;
    cursor: pointer;
}

span.scale-rating input[type=radio] {
    position: absolute;
    -webkit-appearance: none;
    opacity: 0;
    outline: 0 !important;
    /*border-right: 1px solid grey;*/
    height: 33px;

    margin: 0 5px 0 0;

    width: 100%;
    float: left;
    cursor: pointer;
    z-index: 3;
}



span.scale-rating input[type=radio]:last-child {
    border-right: 0;
}



span.scale-rating label:before {
    content: attr(value);
    top: 7px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    vertical-align: middle;
    z-index: 2;
}


.left-panel  {
    margin-left: 0px;
}

body {

    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
  